<template>
  <svg viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33366 5.50032L10.667 5.50033M10.667 5.50033L6.00033 0.833659M10.667 5.50033L6.00033 10.167"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
